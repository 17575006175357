import { FunctionComponent, useState, useRef } from 'react'
import { FaArrowCircleUp } from 'react-icons/fa'
import styled from 'styled-components'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { colours, constants } from 'styles'

interface ScrollArrowProps {
  isButtonVisible: boolean
}

const ScrollArrow = styled.button<ScrollArrowProps>`
  position: fixed;
  visibility: ${(props) => (props.isButtonVisible ? 'visible' : 'hidden')};
  bottom: 4rem;
  right: 3rem;
  width: 4rem;
  height: 4rem;
  :focus {
    outline: none;
  }
  @media ${constants.breakpoints.maxHeader} {
    display: none;
  }
  svg {
    transform: rotate(-20deg);
    opacity: ${(props) => (props.isButtonVisible ? 0.4 : 0)};
    transition: transform 300ms, opacity 500ms;
    :hover {
      transform: rotate(0);
      opacity: 1;
    }
  }
`

const scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

const StyledScrollBar = styled.div`
  position: fixed;
  bottom: 0;
  height: 5px;
  background: ${colours.orange};
  width: 0;
`

export const ScrollHelper: FunctionComponent = () => {
  const scrollbar = useRef<HTMLDivElement>(null)
  const [isButtonVisible, setButtonVisible] = useState(false)
  useScrollPosition(({ currPos }) => {
    const fullHeight = document.body.scrollHeight - window.innerHeight + 1
    if (fullHeight < 150) {
      setButtonVisible(false)
      if (scrollbar.current) {
        scrollbar.current.style.width = '0'
      }
      return
    }
    const yScroll = Math.abs(currPos.y)
    const percentageScrolled = Math.round((yScroll / fullHeight) * 100)
    if (scrollbar.current) {
      scrollbar.current.style.width = `${percentageScrolled}%`
    }
    if (yScroll > 50) {
      if (!isButtonVisible) {
        setButtonVisible(true)
      }
    } else if (isButtonVisible) {
      setButtonVisible(false)
    }
  })
  return (
    <>
      <ScrollArrow isButtonVisible={isButtonVisible} onClick={scrollToTop}>
        <FaArrowCircleUp size="100%" color={colours.orange} />
      </ScrollArrow>
      <StyledScrollBar ref={scrollbar} />
    </>
  )
}
