export const colours = Object.freeze({
  black: '#393939' as const,
  darkBlack: '#222222' as const,
  lightBlack: '#333333' as const,
  white: '#ffffff' as const,
  greyOne: '#6c6c6c' as const,
  greyTwo: '#a4a4a4' as const,
  greyThree: '#b6b6b6' as const,
  greyFour: '#e9e9e9' as const,
  greyFive: '#f6f6f6' as const,
  focusColour: 'blue' as const,
  orange: '#cc6600' as const,
})

export const constants = Object.freeze({
  maxWidth: '1200px' as const,
  headerHeight: '10rem' as const,
  headerLinkFixHeight: '14rem' as const,
  burgerHeight: '3.6rem' as const,
  footerHeight: '7rem' as const,
  particleBannerHeight: '22rem' as const,
  marginPadding: '100px' as const,
  breakpoints: {
    maxHeader: 'screen and (max-width: 850px)' as const,
    mobile: 'screen and (max-width: 570px)' as const,
  },
})
