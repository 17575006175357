import { useState, useContext, useEffect, Dispatch, ReactNode, SetStateAction } from 'react'
import { ModalContext } from './context'
import { ModalClosedCallBack } from './types'

export type UseModalTuple = [Dispatch<SetStateAction<ReactNode>>, ModalClosedCallBack]

export const useModal: () => UseModalTuple = () => {
  const [content, setContent] = useState<ReactNode | null>(null)
  const modalContext = useContext(ModalContext)
  useEffect(() => {
    if (modalContext && content) {
      modalContext!.setModalContent(content)
    }
    return () => {
      if (modalContext && content) {
        modalContext!.setModalContent(null)
      }
    }
  }, [content, modalContext])
  return [setContent, modalContext!.onModalClosed]
}
