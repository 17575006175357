import { useState, FunctionComponent } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { colours, mixins, constants } from 'styles'
import Logo from 'images/tt-logo.inline.svg'
import { BurgerButton } from './BurgerButton'
import { Nav } from './Nav'

const StyledHeader = styled.header`
  position: relative;
  width: 100%;
  top: 0;
  background-color: ${colours.lightBlack};
  background: linear-gradient(180deg, rgba(34, 34, 34, 1) 0%, rgba(51, 51, 51, 1) 62%, rgba(70, 57, 45, 1) 100%);
  border-top: 2px solid ${colours.orange};
  z-index: 99;

  .inner-wrapper {
    ${mixins.innerWrapperMixin}
    height: ${constants.headerHeight};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    display: block;
    padding-top: 1.4rem;
    svg {
      width: 18rem;
    }
  }
`
const StyledBurgerButton = styled(BurgerButton)`
  display: none;
  @media ${constants.breakpoints.maxHeader} {
    display: block;
  }
  @media ${constants.breakpoints.mobile} {
    margin-right: 2rem;
  }
`

const StyledLogoLink = styled(Link)`
  @media ${constants.breakpoints.mobile} {
    margin-left: 2rem;
  }
`

export interface MenuLink {
  name: string
  link: string
}

export interface HeaderProps {
  menuLinks: MenuLink[]
  openSearch: () => void
  isSearchVisible: boolean
  children?: never
  className?: string
}

export const Header: FunctionComponent<HeaderProps> = ({ menuLinks, className, openSearch, isSearchVisible }) => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)
  const closeMenu = () => setMobileMenuVisible(false)
  const toggleMenu = () => setMobileMenuVisible(!mobileMenuVisible)

  return (
    <>
      <StyledHeader data-testid="header" className={className}>
        <div className="inner-wrapper">
          <StyledLogoLink data-testid="logo" className="logo" to="/">
            <Logo aria-label="Logo" />
          </StyledLogoLink>
          <Nav
            menuLinks={menuLinks}
            showMobileMenu={mobileMenuVisible}
            onLinkSelected={closeMenu}
            onSearchSelected={openSearch}
            isSearchVisible={isSearchVisible}
          />
          <StyledBurgerButton
            ariaLabel="Open Menu"
            height={constants.burgerHeight}
            colour={colours.white}
            closeColour={colours.black}
            isActivated={mobileMenuVisible}
            action={toggleMenu}
          />
        </div>
      </StyledHeader>
    </>
  )
}
