import { FunctionComponent } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import { GlobalStyles } from 'styles/globalStyles'
import { MenuLink } from 'components/Header'
import { GlobalContextProvider } from 'components/store/globalContext'

import Page from './Page'

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        menuLinks {
          name
          link
        }
      }
    }
  }
`

interface StaticQueryProps {
  siteMetadata: {
    title: string
    menuLinks: MenuLink[]
  }
}

export const PageWrapper: FunctionComponent = ({ children }) => {
  const { site }: { site: StaticQueryProps } = useStaticQuery(query)

  const { siteMetadata } = site

  return (
    <>
      <Helmet>
        <title>{siteMetadata.title}</title>
        <meta
          name="description"
          content="TruthTable = home of quality analogue electronic music, beats and modular excursions"
        />
        <meta
          name="keywords"
          content="TruthTable, electronic, analogue, 808, modular, dance, IDM, 303, roland, korg, boss, you are not here, creative"
        />
        <meta name="TruthTable" content="truthtable.co.uk" />
      </Helmet>
      <GlobalStyles />
      <GlobalContextProvider initialState={{ cookiesAccepted: false }}>
        <Page menuLinks={siteMetadata.menuLinks}>{children}</Page>
      </GlobalContextProvider>
    </>
  )
}

export default PageWrapper // default needed for layout plugin
