import { useEffect, useRef, useCallback, FunctionComponent, MouseEvent } from 'react'
import styled from 'styled-components'
import { colours, constants } from 'styles'

const StyledModal = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(190, 190, 255, 0.5);
  overflow-y: auto;
`

const CloseButton = styled.button`
  position: absolute;
  background-color: ${colours.black};
  color: ${colours.white};
  width: 7rem;
  height: 5rem;
  right: 0;
  top: 20rem;
  @media ${constants.breakpoints.maxHeader} {
    top: 10px;
  }
  border: 2px solid ${colours.black};
  z-index: 9999;
  :focus {
    outline: none;
    border-color: ${colours.focusColour};
    border-right-color: ${colours.black};
  }
  .cross {
    display: block;
  }
  .cross:before,
  .cross:after {
    position: absolute;
    content: '';
    height: 4rem;
    width: 0.6rem;
    top: 0.4rem;
    left: 2rem;
    background-color: ${colours.white};
  }
  .cross:before {
    transform: rotate(45deg);
  }
  .cross:after {
    transform: rotate(-45deg);
  }
`

export interface ModalProps {
  onModalClosed: () => void
}

export const Modal: FunctionComponent<ModalProps> = ({ children, onModalClosed }) => {
  useEffect(() => {
    if (document) {
      document.querySelector('.header')!.classList.add('modal-blur')
      document.querySelector('.page-wrapper')!.classList.add('modal-blur')
      document.body.style.overflow = 'hidden'
    }

    return () => {
      if (document) {
        document.querySelector('.header')!.classList.remove('modal-blur')
        document.querySelector('.page-wrapper')!.classList.remove('modal-blur')
        document.body.style.overflow = 'auto'
      }
    }
  }, [])

  const wrapperEL = useRef(null)

  const checkForClose = useCallback(
    (e: MouseEvent) => {
      if (e.target === wrapperEL.current) {
        onModalClosed()
      }
    },
    [onModalClosed],
  )

  return (
    <StyledModal ref={wrapperEL} onClick={checkForClose}>
      <CloseButton onClick={onModalClosed} autoFocus data-testid="modal-btn">
        <span className="visually-hidden">Close</span>
        <i className="cross" />
      </CloseButton>
      {children}
    </StyledModal>
  )
}
