import { FunctionComponent, ReactNode, useCallback, useState, useEffect, memo } from 'react'
import styled from 'styled-components'
import { useCookies } from 'react-cookie'
import { constants } from 'styles'
import { Header, MenuLink } from 'components/Header'
import { Footer } from 'components/Footer'
import { ModalWrapper } from 'components/modal'
import { PrivacyNotice } from 'components/PrivacyNotice'
import { SearchBar } from 'components/SearchBar'
import { ScrollHelper } from 'components/ScrollHelper'
import { useGetWidth } from 'components/hooks/useGetWidth'
import { useGlobalContext, globalStateActionTypes } from 'components/store/globalContext'

interface ContentLayoutProps {
  isSearchVisible: boolean
  className?: string
}

const ContentLayout = styled.div<ContentLayoutProps>`
  transform: ${(props) => (props.isSearchVisible ? 'translate3d(0, 0, 0)' : 'translate3d(0, -58px, 0)')};
  transition: transform 200ms;
  overflow-y: hidden;
  width: 100%;
  min-height: calc(100vh - ${constants.headerHeight} - ${constants.footerHeight});

  @media ${constants.breakpoints.maxHeader} {
    transform: translate3d(0, 0, 0);
  }
`

const MemoedHeader = memo(Header)
const MemoedFooter = memo(Footer)

const TT_COOKIE = 'tt_cookie_accepted'
const GLOBAL_ACCEPT = { type: globalStateActionTypes.COOKIE_ACCEPTED }

function setCookieAccepted(
  setCookie: (cookieName: string, cookieValue: boolean) => void,
  globalContextDispatcher: React.Dispatch<any>,
) {
  setCookie(TT_COOKIE, true)
  globalContextDispatcher(GLOBAL_ACCEPT)
}

interface PageProps {
  menuLinks: MenuLink[]
  children: ReactNode
}

export const Page: FunctionComponent<PageProps> = (props) => {
  const { children, menuLinks } = props

  // search
  const [isSearchVisible, setSearchVisible] = useState(false)
  const closeSearch = useCallback(() => setSearchVisible(false), [])
  const openSearch = useCallback(() => setSearchVisible(true), [])

  //scroller
  const [isScrollHelperVisible, setScrollHelperVisible] = useState(false)
  useGetWidth((width: number) => {
    if (width > 850) {
      if (!isScrollHelperVisible) {
        setScrollHelperVisible(true)
      }
    } else if (isScrollHelperVisible) {
      setScrollHelperVisible(false)
    }
  })

  // cookies
  const [isRejected, rejectCookies] = useState(false)
  const [cookies, setCookie] = useCookies([TT_COOKIE])
  const cookiesAccepted = !!cookies[TT_COOKIE]
  const showPrivacyNotice = !cookiesAccepted && !isRejected
  const [{ cookiesAccepted: globalCookiesAccepted }, globalContextDispatch] = useGlobalContext()
  useEffect(() => {
    if (cookiesAccepted && !globalCookiesAccepted) {
      globalContextDispatch(GLOBAL_ACCEPT)
    }
  })

  return (
    <>
      <ModalWrapper>
        <MemoedHeader
          className="header"
          menuLinks={menuLinks}
          openSearch={openSearch}
          isSearchVisible={isSearchVisible}
        />
        <ContentLayout className="page-wrapper" isSearchVisible={isSearchVisible}>
          <SearchBar isVisible={isSearchVisible} closeSearch={closeSearch} />
          {children}
        </ContentLayout>
        <MemoedFooter />
      </ModalWrapper>
      {isScrollHelperVisible && <ScrollHelper />}
      {showPrivacyNotice && (
        <PrivacyNotice
          className="no-js"
          onAccept={() => setCookieAccepted(setCookie, globalContextDispatch)}
          onReject={() => rejectCookies(true)}
        />
      )}
    </>
  )
}

export default Page // default needed for layout plugin
