// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-presskit-tsx": () => import("./../../../src/pages/presskit.tsx" /* webpackChunkName: "component---src-pages-presskit-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-templates-all-artists-tsx": () => import("./../../../src/templates/AllArtists.tsx" /* webpackChunkName: "component---src-templates-all-artists-tsx" */),
  "component---src-templates-all-releases-tsx": () => import("./../../../src/templates/AllReleases.tsx" /* webpackChunkName: "component---src-templates-all-releases-tsx" */),
  "component---src-templates-artist-tsx": () => import("./../../../src/templates/Artist.tsx" /* webpackChunkName: "component---src-templates-artist-tsx" */)
}

