import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { colours, constants, mixins } from 'styles'

const navStyles = css`
  position: relative;
  padding: 6px 0;
  display: inline-block;
  min-width: 50px;
  text-align: center;
  ${mixins.hoverUnderline(colours.greyThree)}
  :hover {
    color: ${colours.greyThree};
    @media ${constants.breakpoints.maxHeader} {
      color: ${colours.greyOne};
    }
  }
`

export const NavLink = styled(Link).attrs(({ to }) => ({
  activeClassName: 'active-link',
  partiallyActive: to !== '/',
}))`
  ${navStyles}
  &.active-link {
    color: ${colours.greyThree};
    @media ${constants.breakpoints.maxHeader} {
      color: ${colours.greyOne};
    }
    ::after {
      width: 50%;
      background-color: ${colours.orange};
    }
  }
`
