import { useCallback, useState, useRef, FunctionComponent, ReactNode } from 'react'
import { createGlobalStyle } from 'styled-components'
import { Modal } from './Modal'
import { ModalApi } from './types'
import { ModalContext } from './context'

export const ModalStyles = createGlobalStyle`
  .modal-blur {
    filter: blur(5px);
  }
`

export const ModalWrapper: FunctionComponent = ({ children }) => {
  const [modalContent, setModalContent] = useState<ReactNode | null>(null)
  const cbRef = useRef<(() => void) | null>(null)
  const apiRef = useRef<ModalApi>({
    setModalContent,
    onModalClosed(cb) {
      cbRef.current = cb
    },
  })

  const memoizedCallback = useCallback(() => {
    if (cbRef.current) {
      cbRef.current()
    }
    setModalContent(null)
    cbRef.current = null
  }, [])
  return (
    <ModalContext.Provider value={apiRef.current}>
      <ModalStyles />
      {children}
      {modalContent && <Modal onModalClosed={memoizedCallback}>{modalContent}</Modal>}
    </ModalContext.Provider>
  )
}
