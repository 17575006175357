import { css } from 'styled-components'

// This is disabled so the jest moduleNameMapper can work with a resolved import
// tslint:disable-next-line: no-submodule-imports
import 'typeface-open-sans/index.css'
import 'typeface-lato/index.css'

export const fontOpenSans = css`
  font-family: 'Open Sans', 'Lucida Grande', Tahoma, Sans-Serif;
`
export const fontLato = css`
  font-family: 'Lato', 'Lucida Grande', Tahoma, Sans-Serif;
`

export const typography = css`
  html {
    font-size: 10px;
  }
  body {
    ${fontOpenSans};
    font-size: 1.5rem;
    line-height: 1.6;
  }
`
