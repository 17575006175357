import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { fontLato, colours, constants } from 'styles'
import SearchIcon from 'images/search.inline.svg'
import { NavLink } from './NavLink'
import { MenuLink } from './Header'

export interface StyledNavProps {
  showMobileMenu: boolean
}

export interface SearchIconProps {
  isSearchVisible: boolean
}

const StyledSearchIcon = styled.li<SearchIconProps>`
  cursor: pointer;
  pointer-events: ${(props) => (props.isSearchVisible ? 'none' : 'auto')};
  opacity: ${(props) => (props.isSearchVisible ? 0.4 : 1)};
  :hover {
    circle,
    path {
      stroke: ${colours.greyThree};
    }
  }
  svg {
    position: relative;
    top: 5px;
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
    @media ${constants.breakpoints.maxHeader} {
      display: none;
    }
  }
`

const StyledNav = styled.nav<StyledNavProps>`
  ${fontLato};
  font-size: 1.8rem;
  font-weight: 700;
  color: ${colours.white};
  width: 60%;
  padding-left: 5rem;

  @media screen and (max-width: 1100px) {
    font-size: 1.6rem;
    width: 66%;
  }
  @media ${constants.breakpoints.maxHeader} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: ${(props) => (props.showMobileMenu ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)')};
    transition: transform 250ms;
    width: 100vw;
    min-width: 260px;
    height: 60vh;
    max-height: 60rem;
    padding-top: 2.8rem;
    padding-left: 0;
    color: ${colours.darkBlack};
    font-size: 2.5rem;
    background-color: ${colours.white};
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    z-index: 3;
  }
  .nav-links {
    position: relative;
    display: flex;
    justify-content: space-between;
    @media ${constants.breakpoints.maxHeader} {
      flex-direction: column;
      align-items: center;
      height: 100%;
    }
  }
`

StyledNav.displayName = 'StyledNav'

export interface NavProps extends SearchIconProps {
  children?: never // good practise
  menuLinks: MenuLink[]
  showMobileMenu: boolean
  onLinkSelected: () => void
  onSearchSelected: () => void
  className?: string
}

export const Nav: FunctionComponent<NavProps> = ({
  menuLinks,
  className,
  showMobileMenu,
  onLinkSelected,
  onSearchSelected,
  isSearchVisible,
}) => (
  <StyledNav className={className} showMobileMenu={showMobileMenu}>
    <ul className="nav-links">
      {menuLinks.map(({ name, link }) => (
        <li key={name}>
          <NavLink to={link} onClick={onLinkSelected}>
            {name.toUpperCase()}
          </NavLink>
        </li>
      ))}
      <StyledSearchIcon isSearchVisible={isSearchVisible} onClick={onSearchSelected}>
        <SearchIcon />
      </StyledSearchIcon>
    </ul>
  </StyledNav>
)
