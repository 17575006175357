import { useRef, useEffect, useState, FunctionComponent, useCallback } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { clearStopWords, Index } from 'elasticlunr'
import { constants, colours } from 'styles'
import SearchIcon from 'images/search.inline.svg'

// This gets rid of the list of words you can't search on such as 'the' or 'you'
clearStopWords()

const query = graphql`
  query SearchIndexQuery {
    siteSearchIndex {
      index
    }
  }
`

const StyledResults = styled.ul`
  width: 100%;
  max-width: 60rem;
  li {
    padding: 1.6rem 0;
    border-bottom: solid 1px #f0b981;
    @media ${constants.breakpoints.maxHeader} {
      padding: 2.4rem 0;
    }

    @media screen and (max-width: 650px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    a {
      color: ${colours.white};
      :hover {
        color: lightgray;
      }
      font-weight: 600;
      font-size: 1.8rem;
      @media ${constants.breakpoints.maxHeader} {
        font-size: 1.6rem;
      }
      @media screen and (max-width: 650px) {
        font-size: 1.4rem;
      }
    }
    :last-of-type {
      padding-bottom: 1.6rem;
      border-bottom: none;
      @media ${constants.breakpoints.maxHeader} {
        padding-bottom: 2.6rem;
      }
    }
  }
`

function getResults(value: string, index: Index<unknown>, resetSearch: () => void) {
  const searchResult = index.search(value, { expand: true }).map(({ ref }) => index.documentStore.getDoc(ref))

  return searchResult.map((res: any) => {
    const linkLabel = res.type === 'release' ? `${res.artist} : ${res.title} (${res.releaseId})` : res.name

    let url = `/artists/${res.slug}`
    if (res.type === 'release') {
      url += `/#${res.releaseId}`
    }
    return (
      <li key={res.id}>
        <Link onClick={resetSearch} to={url}>
          {linkLabel}
        </Link>
      </li>
    )
  })
}

interface StyledSearchBarProps {
  isVisible: boolean
}

const StyledSearchBar = styled.div<StyledSearchBarProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${colours.orange};
  .search-form-elements {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 0.8rem 0;
    width: 80%;
    max-width: 50rem;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    transition: opacity 400ms 200ms;
    @media ${constants.breakpoints.maxHeader} {
      opacity: 1;
    }
    .search-form {
      display: inline-block;
      flex: 1 1 20rem;

      .search-input {
        /* line-height: normal; */
        -webkit-appearance: none;
        line-height: normal;

        height: 4rem;
        width: 100%;
        width: ${(props) => (props.isVisible ? '100%' : 0)};
        transition: width 400ms 200ms;
        border-radius: 6px;
        border: none;
        padding: 0 5rem 0 2rem;
        &::placeholder {
          color: lightgray;
        }
        @media ${constants.breakpoints.maxHeader} {
          width: 100%;
          height: 3.2rem;

          min-width: 200px;
          padding-right: 2rem;
          border-radius: 4px;
          font-size: 16px;
        }
      }
    }

    .search-close {
      position: absolute;
      width: 3.6rem;
      height: 3.6rem;
      @media ${constants.breakpoints.maxHeader} {
        display: none;
      }
      right: 2px;
      top: 1rem;
      background-color: ${colours.orange};
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      :hover {
        background-color: ${colours.lightBlack};
      }
      :before,
      :after {
        position: absolute;
        content: '';
        height: 80%;
        width: 0.5rem;
        top: 0.4rem;
        left: 1.6rem;
        background-color: ${colours.white};
      }
      :before {
        transform: rotate(45deg);
      }
      :after {
        transform: rotate(-45deg);
      }
    }

    svg {
      position: relative;
      display: inline-block;
      width: 3rem;
      height: 4rem;
      flex-shrink: 0;
      margin-right: 1rem;

      @media ${constants.breakpoints.maxHeader} {
        width: 2.4rem;
        height: 3rem;
        top: 2px;
      }
    }
  }
`

interface SearchBarProps extends StyledSearchBarProps {
  closeSearch: () => void
}

export const SearchBar: FunctionComponent<SearchBarProps> = ({ isVisible, closeSearch }) => {
  const [inputVal, setInputVal] = useState('')
  const inputEl = useRef<HTMLInputElement>(null)
  const [results, setResults] = useState<any[]>([])
  const { siteSearchIndex } = useStaticQuery(query)
  const indexRef = useRef(Index.load(siteSearchIndex.index))
  const resetSearch = useCallback(() => {
    setResults([])
    setInputVal('')
    closeSearch()
  }, [setResults, setInputVal, closeSearch])

  useEffect(() => {
    if (inputVal.length > 1) {
      setResults(getResults(inputVal, indexRef.current, resetSearch))
    } else {
      setResults([])
    }
  }, [inputVal, resetSearch])

  if (isVisible && inputEl.current) {
    inputEl.current.focus()
  }

  return (
    <StyledSearchBar isVisible={isVisible}>
      <div className="search-form-elements">
        <SearchIcon aria-label="Search" />
        <form
          role="search"
          onSubmit={(e) => {
            e.preventDefault()
          }}
          className="search-form"
        >
          <input
            ref={inputEl}
            type="search"
            className="search-input focus-fix"
            placeholder="Search by artist or album..."
            maxLength={25}
            value={inputVal}
            onChange={(e) => {
              setInputVal(e.target.value)
            }}
          />
        </form>
        <button className="search-close focus-fix" onClick={resetSearch}>
          <span className="visually-hidden">Close Search</span>
        </button>
      </div>
      <StyledResults>{results.length > 0 && results}</StyledResults>
    </StyledSearchBar>
  )
}
