import { css, FlattenSimpleInterpolation } from 'styled-components'
import { constants, colours } from './styles'

export const mixins = {
  innerWrapperMixin: css`
    max-width: ${constants.maxWidth};
    margin: 0 auto;
    padding-left: ${constants.marginPadding};
    padding-right: ${constants.marginPadding};
    min-width: 300px;
    @media ${constants.breakpoints.maxHeader} {
      padding-left: 3rem;
      padding-right: 3rem;
    }
    @media ${constants.breakpoints.mobile} {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  `,

  textSection: css`
    font-weight: 300;
    font-size: 1.8rem;
    p {
      color: ${colours.greyOne};
      margin-bottom: 2rem;
    }
    a {
      font-size: 1.6rem;
      font-weight: 600;
      text-transform: uppercase;
      border-bottom: 1px solid ${colours.orange};
    }
    h1 {
      color: ${colours.greyOne};
      font-weight: bold;
      font-size: 2.6rem;
      text-align: center;
      margin-bottom: 2rem;
    }
    h2 {
      font-size: 2.8rem;
      font-weight: 400;
      margin-bottom: 0.8rem;
    }
    h3 {
      font-size: 1.8rem;
      font-weight: bold;
      color: ${colours.greyOne};
    }
  `,

  infoGrid: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media ${constants.breakpoints.maxHeader} {
      grid-template-columns: 1fr;
    }
    grid-gap: 1rem 5rem;
  `,

  hoverUnderline(colour = 'black', height = '1px', bottom = '3px', width = '50%'): FlattenSimpleInterpolation {
    return css`
      ::after {
        position: absolute;
        background-color: ${colour};
        content: '';
        height: ${height};
        left: 0;
        right: 0;
        bottom: ${bottom};
        transition: all 0.3s ease 0s;
        width: 0;
        margin: 0 auto;
      }
      :hover {
        ::after {
          width: ${width};
        }
      }
    `
  },
}
