import { createGlobalStyle } from 'styled-components'
import { reset } from './reset'
import { typography } from './typography'
import { colours } from './styles'
import { normalise } from './normalise'

export const GlobalStyles = createGlobalStyle`
  ${normalise}
  ${reset}
  ${typography}
  body {
    background-color: ${colours.greyFour};
    color: ${colours.black};
  }

  .no-js {
    display: none;
  }

  .visually-hidden {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
  }

  .hide-accessibly {
    position: absolute !important;
    opacity: 0;
    pointer-events: none;
  }

  .focus-fix:focus {
    outline: none !important;
    border: 1px solid red;
  }

  .bold {
    font-weight: 600;
  }

  .modal-blur {
    filter: blur(5px);
  }

  input {
    /* line-height: normal; */
    -webkit-appearance: none;
  }
`
