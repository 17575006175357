import { FunctionComponent } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { mixins, colours, constants } from 'styles'
import FacebookSVG from 'images/facebook.svg'
import TwitterSVG from 'images/twitter.svg'
import InstagramSVG from 'images/instagram.svg'

const StyledFooter = styled.div`
  width: 100%;
  background-color: ${colours.white};
  border-top: 1px solid ${colours.orange};
  margin-bottom: 5px;
  @media ${constants.breakpoints.maxHeader} {
    margin-bottom: 0;
  }
  .inner-wrapper {
    ${mixins.innerWrapperMixin}
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 10px;
    min-height: ${constants.footerHeight};
  }
`

const Copyright = styled.div`
  position: relative;
  @media ${constants.breakpoints.mobile} {
    top: -1rem;
  }
  color: ${colours.greyOne};
  .copyright {
    font-weight: 600;
  }
  .privacy {
    padding-left: 5px;
    text-decoration: underline;
    @media ${constants.breakpoints.mobile} {
      position: absolute;
      display: inline-block;
      left: 0;
      top: 2rem;
    }
  }
`

const SocialMedia = styled.ul`
  display: flex;
  align-content: center;
`

const SocialLink = styled.span`
  display: block;
  width: 2rem;
  background-repeat: no-repeat;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 20px;
`

const Facebook = styled(SocialLink)`
  background-image: url(${FacebookSVG});
`

const Twitter = styled(SocialLink)`
  background-image: url(${TwitterSVG});
`

const Instagram = styled(SocialLink)`
  background-image: url(${InstagramSVG});
`

interface FooterProps {
  children?: never
}

export const Footer: FunctionComponent<FooterProps> = () => (
  <StyledFooter>
    <div className="inner-wrapper">
      <Copyright className="copyright">
        © TruthTable {new Date().getFullYear()}
        <Link className="privacy" to="/privacy">
          [Privacy Notice]
        </Link>
      </Copyright>
      <SocialMedia>
        <li>
          <a href="https://www.facebook.com/truthtable0/" target="_blank" rel="noreferrer nofollow noopener">
            <Facebook>Find us on facebook</Facebook>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/TruthTable3" target="_blank" rel="noreferrer nofollow noopener">
            <Twitter>Find us on twitter</Twitter>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/truthtable_music/" target="_blank" rel="noreferrer nofollow noopener">
            <Instagram>Find us on instagram</Instagram>
          </a>
        </li>
      </SocialMedia>
    </div>
  </StyledFooter>
)
