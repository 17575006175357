import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { mixins, colours, constants } from 'styles'

const query = graphql`
  query {
    page: contentfulPage(name: { eq: "privacy" }) {
      config: pageConfig {
        bannerText
        acceptLabel
        rejectLabel
      }
    }
  }
`

const StyledPrivacyNotice = styled.div`
  position: fixed;
  bottom: 0;
  background-color: rgba(40, 40, 40, 0.5);
  min-height: 250px;
  width: 100%;
  padding: 5rem;
  z-index: 9999999;
`

const InnerWrapper = styled.div`
  display: flex;
  @media ${constants.breakpoints.maxHeader} {
    flex-direction: column;
  }
  align-items: center;
  ${mixins.innerWrapperMixin}
  padding: 3rem;
  color: ${colours.white};
  background-color: ${colours.black};
  border-radius: 1rem;
  height: 100%;
  .privacy-text {
    font-size: 1.8rem;
    flex-grow: 1;
    margin-right: 4rem;
    @media ${constants.breakpoints.maxHeader} {
      margin-right: 0;
      margin-bottom: 2rem;
    }
    p {
      margin-bottom: 1rem;
    }
  }
  .privacy-link {
    padding-left: 0.5rem;
    text-decoration: underline;
  }
  .btn-wrapper {
    display: flex;
    @media ${constants.breakpoints.mobile} {
      flex-direction: column;
    }
  }
`

const StyledButton = styled.button`
  font-size: 1.6rem;
  font-weight: 400;
  min-width: 14rem;
  border: 2px solid ${colours.orange};
  padding: 1rem 0.6rem;
  border-radius: 5px;
  margin: 2rem;
`

interface PrivacyNoticeConfig {
  page: {
    config: {
      bannerText: string
      acceptLabel: string
      rejectLabel: string
    }
  }
}

export interface PrivacyNoticeProps {
  children?: never
  onAccept: () => void
  onReject: () => void
  className?: string
}

export const PrivacyNotice: FunctionComponent<PrivacyNoticeProps> = ({ onAccept, onReject, className }) => {
  const { page }: PrivacyNoticeConfig = useStaticQuery(query)
  const { config } = page
  return (
    <StyledPrivacyNotice className={className}>
      <InnerWrapper>
        <div className="privacy-text">
          <p>{config.bannerText}</p>
          <p>
            For more details see our
            <Link className="privacy-link" to="/privacy">
              privacy policy.
            </Link>
          </p>
        </div>
        <div className="btn-wrapper">
          <StyledButton data-testid="accept-btn" type="button" onClick={onAccept}>
            {config.acceptLabel}
          </StyledButton>
          <StyledButton type="button" onClick={onReject}>
            {config.rejectLabel}
          </StyledButton>
        </div>
      </InnerWrapper>
    </StyledPrivacyNotice>
  )
}
