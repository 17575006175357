import { useEffect, useState } from 'react'

const getWidth = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  }
  return 0
}

export const useGetWidth = (callback: (width: number) => void): void => {
  const [width, setWidth] = useState(getWidth())
  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setWidth(getWidth())
    }
    // set resize listener
    if (window) {
      window.addEventListener('resize', resizeListener)
    }

    setWidth(getWidth())

    callback(width)

    // clean up function
    return () => {
      // remove resize listener
      if (window) {
        window.removeEventListener('resize', resizeListener)
      }
    }
  }, [setWidth, width, callback])
}
