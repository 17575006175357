import { FunctionComponent } from 'react'
import styled from 'styled-components'

interface BurgerBarsProps {
  colour?: string
  closeColour?: string
  isActivated?: boolean
}

export interface BurgerButtonProps extends BurgerBarsProps {
  height: string
  ariaLabel?: string
  className?: string
  action?: () => void
}

const BurgerWrapper = styled.div<BurgerButtonProps>`
  position: relative;
  height: ${(props) => props.height};
  width: ${(props) => props.height};
  cursor: pointer;
  z-index: 999;
`

const Bar = styled.span<BurgerBarsProps>`
  position: absolute;
  display: block;
  background-color: ${(props) => (props.isActivated ? props.closeColour : props.colour)};
  width: 100%;
  height: 20%;
`

const animSpeed = '100ms'
const transition = (props: BurgerBarsProps) => {
  if (props.isActivated) {
    return `top ${animSpeed},transform ${animSpeed} ${animSpeed}`
  }
  return `transform ${animSpeed}, top ${animSpeed} ${animSpeed}`
}

const TopBar = styled(Bar)`
  top: ${(props) => (props.isActivated ? '40%' : 0)};

  transform: ${(props) => (props.isActivated ? 'rotate(45deg)' : 'rotate(0)')};
  transition: ${transition};
`
const MiddleBar = styled(Bar)`
  top: 38%;
  opacity: ${(props) => (props.isActivated ? 0 : 1)};
`
const BottomBar = styled(Bar)`
  top: ${(props) => (props.isActivated ? '40%' : '80%')};
  transform: ${(props) => (props.isActivated ? 'rotate(-45deg) ' : 'rotate(0)')};
  transition: ${transition};
`

export const BurgerButton: FunctionComponent<BurgerButtonProps> = ({
  height,
  colour = 'white',
  closeColour = 'black',
  ariaLabel,
  isActivated = false,
  className,
  action,
}) => (
  <BurgerWrapper
    data-testid="burger"
    aria-label={ariaLabel || ''}
    role="button"
    aria-pressed={isActivated}
    height={height}
    className={className}
    onClick={action}
  >
    <TopBar isActivated={isActivated} colour={colour} closeColour={closeColour} />
    <MiddleBar isActivated={isActivated} colour={colour} closeColour={closeColour} />
    <BottomBar isActivated={isActivated} colour={colour} closeColour={closeColour} />
  </BurgerWrapper>
)
