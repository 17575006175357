import { createContext, useContext, useReducer, FunctionComponent, Dispatch, ReactNode } from 'react'

type GlobalState = {
  cookiesAccepted: boolean
}

type GlobalContextType = [state: GlobalState, dispatch: Dispatch<any>]

const GlobalContext = createContext<GlobalContextType | null>(null)
GlobalContext.displayName = 'GlobalContext'

export const globalStateActionTypes = {
  COOKIE_ACCEPTED: 'cookie accepted',
}

function globalStateReducer(state: GlobalState, action: any) {
  switch (action.type) {
    case globalStateActionTypes.COOKIE_ACCEPTED: {
      return {
        ...state,
        cookiesAccepted: true,
      }
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

type GlobalContextProviderProps = {
  children: ReactNode
  initialState: GlobalState
}

export const GlobalContextProvider: FunctionComponent<GlobalContextProviderProps> = ({ initialState, children }) => {
  const reducer = useReducer(globalStateReducer, initialState)
  return <GlobalContext.Provider value={reducer as GlobalContextType}>{children}</GlobalContext.Provider>
}

export const useGlobalContext = (): GlobalContextType => {
  // why doe this work in Page.tsx
  const context = useContext(GlobalContext)
  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalContext.Provider')
  }
  return context as GlobalContextType
}
